html {
  overflow: auto;
}
.body {
  overflow: auto;
  height: 100vh;
  width: 100%;
  background: white;
}
.screens {
  flex: 1;
  padding: 0 30px;
  max-width: 1500px;
}

.screensSubMenu {
  flex: 1;
  padding: 0 20px;
}

@media (max-width: 374px) {
  .screens,
  .screensSubMenu {
    flex: 1;
    padding: 0 16px;
  }
}

@media (min-width: 768px) {
  .screens,
  .screensSubMenu {
    flex: 1;
    padding: 0 30px;
    margin-top: 2em;
  }
}

@media (min-width: 1200px) {
  .body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex: 1;
  }
  .screens {
    flex: 1;
    flex-direction: column;
    padding: 1rem 6rem 1.5rem 6rem;
    margin: 0 auto;
  }
  .screensSubMenu {
    flex: 1;
    flex-direction: column;
    padding: 1rem 3.5rem 0 3.5rem;
    margin: 0;
  }
}

@media (min-width: 1400px) {
  .body {
    height: 100vh;
  }
}

@media (min-width: 1600px) {
  .body {
    height: 100vh;
  }
}
@media (min-width: 1900px) {
  .body {
    height: 100vh;
  }
}
