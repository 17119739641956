.changePasswordSuccessTitle {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #152134;
  margin: 0;
  padding: 0;
}

.changePasswordSuccessUsername {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #141f2d;
  margin: 0;
  padding: 0;
}

.changePasswordSuccessUserEmail {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: -0.12px;
  text-align: center;
  color: #a5a7a9;
  margin: 0;
  padding: 0;
}

.changePasswordSuccessNote {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #18243c;
  margin: 0;
  padding: 0;
}
.changePasswordSuccessAvatar {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto;
}
