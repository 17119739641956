.inputWidth {
  width: 100%;
  text-indent: 40px;
}

.datepickerInput {
  width: 100%;
}

.stepContainer {
  display: flex;
  flex-direction: column;
}

.stepBox {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.stepBody {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.stepLogo {
  display: flex;
  padding: 4rem;
  align-items: center;
  justify-content: center;
}

.logoImage {
  width: 100%;
}

.logoContainer {
  margin: 1em 0;
  display: flex;
  flex: 0 0 60px;
  justify-content: center;
  align-items: center;
}

.currentStep {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #b9b9b9;
}

.inputContainer {
  display: flex;
  position: relative;
  flex-direction: column;
}

.phrase {
  color: #6bccfd;
}

.primaryText {
  color: #0ac77b;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
}

.errorIcon {
  display: none;
}

.errorText {
  margin-bottom: 10px;
  text-align: center;
}

.backButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1.5rem 0 0 0;
  border: none;
  background: transparent;
}

.paddingTop {
  padding-top: 0;
}

/* enter login code */
.smsCodeForm {
  display: flex;
  justify-content: space-around;
  margin: 0 1em;
}

.controls {
  margin-top: 2em;
}

.newSecondStepAuthMainContainer {
  grid-row: 1;
  grid-column: 1;
}

.coverImage {
  object-fit: cover;
  max-width: 100%;
}

.newSecondStepAuthMainContainerTitle {
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #152134;
}
.newSecondStepAuthMainContainerChangePasswordSubTitle {
  color: #141f2d;
  padding: 0;
  margin: 10px 0 0 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #141f2d;
}

.newSecondStepAuthMainContainerSubTitle {
  font-size: 18px;
  color: #141f2d;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.newSecondStepAuthMainContainerInput {
  font-size: 28px;
  color: #3fc78e;
  padding: 0;
  margin: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.newSecondStepAuthMainContainerNote {
  font-size: 15px;
  color: #141f2d;
  text-align: left;
  padding: 0;
  margin: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.newSecondStepAuthMainContainerSubNote {
  font-size: 16px;
  color: #141f2d;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.newSecondStepAuthMainContainerDetails {
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b9c3c7;
  transition: all linear 160ms;
}

.newSecondStepAuthMainContainerDetailsActive {
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3fc78e;
  transition: all linear 160ms;
}

.newSecondStepAuthMainContainerFooterText {
  font-size: 10px;
  color: #a5a7a9;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media (min-width: 480px) and (max-width: 767px) {
  .logoImage {
    width: 66%;
  }

  .controls {
    display: flex;
    margin: 1em;
    padding: 0 5em;
  }

  .stepBox {
    padding: 0;
  }
}

/* Tablet */
@media (min-width: 768px) {
  .inputWidth {
    width: 100%;
  }

  .inputDigit {
    width: 100px;
    text-align: center;
    text-indent: 0px;
  }

  .logoImage {
    width: 50%;
  }

  .smsCodeForm {
    display: flex;
    justify-content: space-around;
    margin: 0 4em;
  }

  .stepContainer {
    display: flex;
    flex-direction: column;
  }

  .stepBox {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 70%;
    width: 100%;
  }

  .controls {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin: 2em auto 0;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .errorIcon {
    display: block;
  }

  .errorText {
    margin-top: 10px;
    text-align: left;
  }

  .paddingTop {
    padding-top: 2em;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .stepContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .logoImage {
    width: 100%;
  }

  .stepBox {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    max-width: 66.66667%;
    margin: 1.5rem 0 0.5rem 0;
    height: auto;
    border: solid 5px #efefef;
    border-radius: 10px;
  }

  .stepBody {
    flex: 2;
  }

  .stepLogo {
    border-right: 1px solid #dcdcdc;
    flex: 1;
    padding: 0 10px;
  }

  .logoContainer {
    flex: 1;
    margin: 0;
    border-right: 2px solid #efefef;
  }

  .currentStep {
    display: flex;
    width: auto;
    font-size: 0.9rem;
    justify-content: flex-end;
    padding: 1rem 1rem 0 1rem;
  }

  .controls {
    margin: 1em 0 3rem 0;
    flex: 1;
  }

  .backButton {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: -3rem;
  }

  .datepickerInput {
    width: 100%;
  }
}
