html,
body,
#root {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100vh;
}

body {
  font-family: "Kumbh Sans", sans-serif;
  color: #7a7a7a;
  margin: 0;
  /* background-color: #EFEFEF; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  text-decoration: none;
  font-weight: 300;
  color: #141f2d;
  cursor: pointer;
}

h6,
small,
input {
  color: #7a7a7a;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 300;
}

p {
  color: #141f2d;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.badge {
  margin-top: 20px;
  height: 17px;
  max-width: 39.46px;
  font-size: 9px;
  font-weight: bold;
  white-space: nowrap;
  color: #0067a8;
  background-color: rgba(0, 103, 168, 0.16);
  -webkit-border-radius: 12.5px;
  -moz-border-radius: 12.5px;
  border-radius: 12.5px;
  border: 1px solid #0067a8;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.badge.important {
  color: #d23241;
  background-color: rgba(210, 50, 65, 0.16);
  border: 1px solid #d23241;
  max-width: 74.46px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 195px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 15px;
  right: 0;
  border-radius: 12px;
  padding: 0;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 15px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

input {
  color: inherit;
  border-top: none;
  border-right: none;
  border-left: none;
  font-family: "Kumbh Sans", sans-serif;
}

.bigAdornment {
  font-size: 3.4rem;
  color: black;
  font-family: "Kumbh Sans";
}

.CustomModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f8;
  padding: 18px 30px;
}

.successModalBody {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.receiptModalBody {
  justify-content: left;
  padding: 1rem;
  color: rgb(27, 27, 27);
  font-size: 0.98rem;
}

.receiptModalValue {
  justify-content: right;
  padding: 1rem;
  margin-left: 7rem;
  color: #7a7a7a;
}

select {
  width: auto;
  color: #7a7a7a;
  max-height: 100%;
  background-color: #efefef;
  border: 0;
  border-radius: 10px;
  padding: 0.15rem 0.25rem;
  box-shadow: none;
}

input::-webkit-input-placeholder {
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #7a7a7a;
}

#amountRequested::placeholder {
  color: black;
  opacity: 1;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #f4f6f8;
}

.descriptionHistory {
  display: inline-block;
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  color: #7a7a7a;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  font-size: 12px;
  letter-spacing: 1px;
}

@media (max-width: 991px) {
  body {
    font-size: 1.25rem;
    background-color: white;
  }
}

@media (min-width: 992px) {
  body {
    font-size: 1.25rem;
    line-height: 1.7rem;
  }
}

.LoanProgressContainer {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.LoanProgressContainer p {
  font-size: 12px;
  font-weight: normal;
  color: #acabb2;
}

.LoanProgressContainer span {
  font-size: 12px;
  font-weight: bold;
  color: #acabb2;
}
