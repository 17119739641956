/* Login Page ~~~~~~~~~~~~~~~~~~~ */

/* Mobile */
.container {
  display: grid;
  height: 100vh;
  width: 100%;
}
.grid {
  display: grid;
  background-color: white;
}
.banner {
  width: 100%;
}
.footer {
  margin: 0;
  padding: 0 2em;
}
.footerIcons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.footerIcons > img {
  height: 55px;
}

.footerText {
  font-weight: 300;
  font-size: 0.75rem;
  text-align: center;
}

@media (min-width: 480px) and (max-width: 767px) {
  .banner {
    width: 100%;
  }
  .footerText {
    font-weight: 300;
    font-size: 0.75rem;
    text-align: center;
  }

  .footer {
    margin: 0;
    padding: 0 2em;
  }
  .footerIcons > img {
    width: 60px;
    height: 60px;
  }
}

/* Tablet */
@media (min-width: 768px) {
  .container {
    display: flex;
    height: auto;
    width: 100%;
  }
  .grid {
    width: 85%;
    margin: auto;
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    padding: 0 6em;
  }
  .footerText {
    text-align: center;
  }
  .footerText {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .banner {
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .container {
    display: flex;
    width: 100%;
  }
  .grid {
    display: flex;
    width: 85%;
    height: 90%;
    background-color: white;
    margin: auto;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .footer {
    display: flex;
    flex-direction: row;
    padding: 3rem 3rem 1rem 0;
  }
  .footerIcons {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  .footerText {
    display: flex;
    flex: 3;
    font-weight: 300;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }
  .footerIcons > img {
    max-height: 66px;
    max-width: 55px;
  }
}
