.desktopFx {
  flex: 0;
}

.phoneUsageSearchFilters {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .phoneUsageSearchFilters {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .desktopFx {
    flex: 1;
  }
  .phoneUsageSearchFilters {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1400px) {
  .desktopFx {
    flex: 1;
  }
  .phoneUsageSearchFilters {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1600px) {
  .desktopFx {
    flex: 1;
  }
  .phoneUsageSearchFilters {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1900px) {
  .desktopFx {
    flex: 1;
  }
  .phoneUsageSearchFilters {
    display: flex;
    flex-direction: row;
  }
}
